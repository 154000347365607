import { useNavigate } from "react-router-dom";
import { Box, Grid, Button, Typography, Snackbar, Slide, SlideProps, SnackbarContent, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, FormGroup, Drawer, Checkbox, Stack, TextField, InputAdornment, Tooltip } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BarcodeIcon from '../../icons/barcodeIcon.svg';
import SuccessIcon from '../../icons/successIcon.svg';
import CloseIcon from '../../icons/closeIcon.svg';
import CrossIcon from '../../icons/crossIcon.png';
import { useEffect, useRef, useState } from "react";
import UploadIcon from '../../icons/uploadIcon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import SearchIcon from '../../icons/SearchIcon.svg';
import './BarcodeListEmp.module.css';


const BarcodeEmployee = () => {
    const router = useNavigate();
    // const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [barcodeSnackbarOpen, setBarcodeSnackbarOpen] = useState(false);
    const [state, setState] = useState({ right: false });
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
    const [rows, setRows] = useState<BarcodeItem[]>([]);
    const [filteredData, setFilteredData] = useState<BarcodeItem[]>([]);
    const [brandData, setBrandData] = useState<DataOption[]>([]);
    const [productData, setProductData] = useState<DataOption[]>([]);
    const [assigneeData, setAssigneeData] = useState<DataOption[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<string[]>([]);
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const nav = useNavigate();
    const size = 20;
    const [moreData, setMoreData] = useState(true);

    interface BarcodeItem {
        sno: number,
        barcode: any,
        brandName: any,
        productName: any,
        description: any,
        sku: any,
        requestedDate: any,
        assignedDate: any,
        employeeName: any,
        empNo: any,
        assignee: any
    }
    interface DataOption {
        value: string;
        label: string;
    }

    useEffect(() => {
        getBrandData();

        const handler = setTimeout(() => {
            getBarcodeList(page, size);
        }, 200);
        return () => clearTimeout(handler);
    }, [page]);

    const getBarcodeList = async (page: number, size: number) => {
        if (!moreData) {
            setLoading(false);
            return;
        }

        const token = sessionStorage.getItem('AccessToken');
        try {
            const barcodeList = await axios.get(
                `https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/user/getBarcodes?page=${page}&size=${size}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (Array.isArray(barcodeList?.data) && barcodeList?.data.length > 0) {
                setRows((prevData) => [...prevData, ...barcodeList.data]);
                setMoreData(true);
            } else {
                setMoreData(false);
            }
        } catch (err) {
            console.error('Error fetching employee barcode list:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        if (
            document.body.scrollHeight - 300 <
            window.scrollY + window.innerHeight
        ) {
            setPage((prevPage) => prevPage + 1);

        }
    };


    function debounce(func: any, delay: any) {
        let timeoutId: any;
        return function (...args: any) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    useEffect(() => {
        const debouncedScroll = debounce(handleScroll, 300);
        window.addEventListener("scroll", debouncedScroll);

        return () => {
            window.removeEventListener("scroll", debouncedScroll);
        };
    }, []);

    const formatDate = (dateString: any) => {
        if (!dateString) {
            return '-';
        }
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    // const handleOpenSnackbar = () => {
    //     setSnackbarOpen(true);
    // };

    // const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setSnackbarOpen(false);
    // };

    const handleCloseBarcodeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setBarcodeSnackbarOpen(false);
    };
    type Anchor = 'right';

    const SlideTransition = (props: SlideProps) => {
        return <Slide {...props} direction="left" />;
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedCheckboxes((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );

        if (brandData.some((brand) => brand.value === value)) {
            const updatedSelectedBrands = selectedCheckboxes.includes(value)
                ? selectedCheckboxes.filter((item) => item !== value && brandData.some((brand) => brand.value === item))
                : [...selectedCheckboxes, value].filter((item) => brandData.some((brand) => brand.value === item));
            setSelectedBrand(updatedSelectedBrands);
        }
    };

    const list = (anchor: Anchor) => (
        <Box sx={{ width: 350, overflowY: 'scroll' }} role="presentation">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1.5vh' }}>
                <Typography variant="h6">Filters</Typography>
                <Box>
                    <span style={{ color: 'red' }}>Close</span>
                    <img onClick={() => setFilterDrawerOpen(false)} src={CloseIcon} style={{ cursor: 'pointer' }} alt="close" />
                </Box>
            </Box>
            <Grid item xs={3} style={{ marginInline: '3vh' }}>
                {/* <TextField
                    id="fsrch"
                    name="fsrch"
                    placeholder="Search"
                    size="small"
                    style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                        }
                    }}
                    onChange={handleSearchChange}
                /> */}
            </Grid>
            <Accordion sx={{ outline: 'none', boxShadow: 'none', marginBlockStart: '2vh' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="body1">Brand</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1.9vh', marginBlockEnd: '-3vh' }}>
                    <FormGroup>
                        {brandData.map(option => (
                            <FormControlLabel
                                sx={{ marginBlock: '-7px' }}
                                key={option.value}
                                control={
                                    <Checkbox
                                        checked={selectedCheckboxes.includes(option.value)}
                                        onChange={handleCheckboxChange}
                                        value={option.value}
                                    />
                                }
                                label={option.label}
                            />
                        ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ outline: 'none', boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="body1">Product</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1.9vh', marginBlockEnd: '-3vh' }}>
                    <FormGroup>
                        {productData.map(option => (
                            <FormControlLabel
                                sx={{ marginBlock: '-7px' }}
                                key={option.value}
                                control={
                                    <Checkbox
                                        checked={selectedCheckboxes.includes(option.value)}
                                        onChange={handleCheckboxChange}
                                        value={option.value}
                                    />
                                }
                                label={option.label}
                            />
                        ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ outline: 'none', boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="body1">Barcode</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1.9vh', marginBlockEnd: '-3vh' }}>
                    <FormGroup>
                        {['Assigned', 'UnAssigned'].map(option => (
                            <FormControlLabel
                                sx={{ marginBlock: '-7px' }}
                                key={option}
                                control={
                                    <Checkbox
                                        checked={selectedCheckboxes.includes(option)}
                                        onChange={(e) => handleStaticCheckboxChange(e.target.value)}
                                        value={option}
                                    />
                                }
                                label={option}
                            />
                        ))}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
            <Stack spacing={2} direction="row" style={{ marginInlineStart: '25vh', marginBlockStart: '5vh' }}>
                <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={handleClearFilters}>Clear</Button>
                <Button variant="contained" sx={{ textTransform: 'none' }} onClick={handleApplyClick}>Apply</Button>
            </Stack>
        </Box>
    );

    const handleStaticCheckboxChange = (value: string) => {
        const updatedCheckboxes = selectedCheckboxes.filter(item => item !== 'Assigned' && item !== 'UnAssigned');
        setSelectedCheckboxes([...updatedCheckboxes, value]);
    };


    // const getFilteredData = async (brandNames: string[], productNames: string[], assignee: string[]) => {
    //     const token = sessionStorage.getItem('AccessToken');
    //     try {
    //         const filteredData = await axios.post(`https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/user/filterOfBarcodes?brandNames=${brandNames}&productNames=${productNames}&statuses=${assignee}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         });
    //         setFilteredData(filteredData.data);
    //         setShowFilteredData(true);
    //         setFilterDrawerOpen(false);
    //     } catch (error) {
    //         console.error('Error fetching filtered data:', error);
    //     }
    // };

    const getFilteredData = async (brandNames: string[], productNames: string[], assignee: string[]) => {
        const token = sessionStorage.getItem('AccessToken');
        try {
            const filteredData = await axios.post(
                `https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/user/filterOfBarcodes?brandNames=${brandNames}&productNames=${productNames}&statuses=${assignee}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            setFilteredData(filteredData.data);
            setShowFilteredData(true);
            setFilterDrawerOpen(false);
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    };



    const getBrandData = async () => {
        const token = sessionStorage.getItem('AccessToken');
        try {
            const brandData = await axios.get('https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/user/brandsOfBarcodes', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            );
            const formattedData = brandData.data.map((item: string) => ({
                value: item,
                label: item
            }));
            setBrandData(formattedData);
        } catch (error) {
            console.error('Error fetching brand data:', error);
        }
    };


    const getProductData = async (brandNames: string[]) => {
        const token = sessionStorage.getItem('AccessToken');
        try {
            const responses = await Promise.all(
                brandNames.map((brandName) =>
                    axios.get(`https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/user/productsOfBarcodes`, {
                        params: {
                            brandName
                        },
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                )
            );
            const formattedData = responses.flatMap(response =>
                response.data.map((item: string) => ({
                    value: item,
                    label: item
                }))
            );
            setProductData(formattedData);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };


    useEffect(() => {
        if (selectedBrand.length > 0) {
            getProductData(selectedBrand);
        } else {
            setProductData([]);
        }
    }, [selectedBrand]);

    useEffect(() => {
        if (selectedBrand.length > 0 && selectedCheckboxes.some((item) => productData.some((product) => product.value === item))) {
            const selectedProducts = selectedCheckboxes.filter((item) =>
                productData.some((product) => product.value === item)
            );
        } else {
            setAssigneeData([]);
        }
    }, [selectedCheckboxes, productData]);

    const handleApplyClick = () => {
        const selectedBrands = selectedCheckboxes.filter((item) =>
            brandData.some((brand) => brand.value === item)
        );
        const selectedProducts = selectedCheckboxes.filter((item) =>
            productData.some((product) => product.value === item)
        );
        const selectedAssignee = selectedCheckboxes.filter((item) =>
            item === "Assigned" || item === "UnAssigned"
        );

        getFilteredData(selectedBrands, selectedProducts, selectedAssignee);
    };

    // const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const fileInput = event.target;
    //     const file = fileInput.files?.[0];
    //     if (file) {
    //         const formData = new FormData();
    //         formData.append("file", file);
    //         const token = sessionStorage.getItem('AccessToken');
    //         try {
    //             const response = await axios.post("https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/admin/upload", formData, {
    //                 headers: {
    //                     "Content-Type": "multipart/form-data",
    //                     Authorization: `Bearer ${token}`,
    //                 }
    //             });
    //             setMessageType('success');
    //             setSnackbarMessage('File uploaded successfully');
    //             getBarcodeList();
    //         } catch (error: unknown) {
    //             let errorMessage = 'An unexpected error occurred';
    //             if (axios.isAxiosError(error) && error.response) {
    //                 errorMessage = error.response.data?.message || errorMessage;
    //             }
    //             setMessageType('error');
    //             setSnackbarMessage(errorMessage);
    //         } finally {
    //             setBarcodeSnackbarOpen(true);
    //             fileInput.value = '';
    //         }
    //     }
    // };


    const handleClearFilters = () => {
        setSelectedCheckboxes([]);
        setSelectedBrand([]);
        setFilteredData([]);
        setShowFilteredData(false);
        getBarcodeList(page, size);
    };


    const getSearchData = async (searchText: any) => {
        const token = sessionStorage.getItem('AccessToken');
        setRows([]);
        try {
            const res = await axios.get(`https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/user/searchByBarcodeAndBrandAndProduct?searchText=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRows(res.data);
        }
        catch (error) {
            console.log('Error fetching Data', error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                getSearchData(searchTerm);
            } else {
                // getBarcodeList(page, size);0
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    // const snackbarContentProps = {
    //     message: (
    //         <div style={{ display: 'flex', alignItems: 'center' }}>
    //             <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
    //                 <img src={SuccessIcon} alt="" />&nbsp;
    //                 <span style={{ marginLeft: '8px' }}>Barcode downloaded successfully</span>
    //             </div>&nbsp;&nbsp;
    //             <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //                 <img src={CloseIcon} onClick={handleCloseSnackbar} style={{ cursor: 'pointer' }} alt="" />
    //             </div>
    //         </div>
    //     ),
    //     sx: {
    //         backgroundColor: 'white',
    //         color: 'green',
    //         borderRadius: '8px',
    //         padding: '1.5vh'
    //     },
    // };

    // const barcodeSnackbarContentProps = {
    //     message: (
    //         <div style={{ display: 'flex', alignItems: 'center' }}>
    //             <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
    //                 <img src={SuccessIcon} alt="" />&nbsp;
    //                 <span style={{ marginLeft: '8px' }}>Barcodes uploaded successfully</span>
    //             </div>&nbsp;&nbsp;
    //             <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //                 <img src={CloseIcon} onClick={handleCloseBarcodeSnackbar} style={{ cursor: 'pointer' }} alt="" />
    //             </div>
    //         </div>
    //     ),
    //     sx: {
    //         backgroundColor: 'white',
    //         color: 'green',
    //         borderRadius: '8px',
    //         padding: '1.5vh'
    //     },
    // };

    // const handleUploadClick = () => {
    //     if (fileInputRef.current) {
    //         fileInputRef.current.click();
    //     }
    // };

    return (
        <Grid item xs={8} sx={{ marginTop: '80px' }}>
            <Box>
                <Box className="back-icon" sx={{ marginBlockEnd: '1vh', display: 'flex', alignItems: 'center' }}>
                    <img className="icon" onClick={() => router('/barcodeListEmp')} alt="back icon" style={{ marginLeft: '4vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/icon/back_arrow.svg" />
                    <Typography sx={{ marginLeft: '0%', fontSize: '17px' }}>Back</Typography>
                </Box>
                <Grid container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginBlockEnd: '4vh' }}>
                    <Grid sx={{ flexBasis: "60%", marginLeft: '5vh' }} item xs={6}>
                        <Typography variant="h6">Barcode List</Typography>
                    </Grid>
                    <Grid sx={{ flexBasis: "40%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginInlineEnd: '5%', alignItems: 'center' }} item xs={4}>
                        <Grid item xs={6}>
                            <TextField
                                id="fsrch"
                                name="fsrch"
                                placeholder="Search"
                                size="small"
                                style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    }
                                }}
                                onChange={handleSearchChange}
                            />
                        </Grid>
                        <img className="icon" onClick={() => setFilterDrawerOpen(true)} alt="filter icon" style={{ marginLeft: '-8vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/filterIcon.svg" />
                        {/* <input
                            type="file"
                            accept=".xlsx,.csv"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                        /> */}
                        {/* <Button sx={{ borderRadius: '1vh', textTransform: 'none', marginInlineStart: '-8vh', marginRight: '1vh' }} variant="outlined" onClick={handleUploadClick} endIcon={<img src={UploadIcon} alt="UploadIcon" />}> Upload</Button> */}
                    </Grid>
                </Grid>
                <TableContainer className="custom-scrollbar" component={Paper} style={{ marginInlineStart: '2vh', width: '99%', overflowY: 'scroll', overflowX: 'scroll' }}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow style={{ position: 'sticky', top: 0, backgroundColor: '#f0f0f0', zIndex: 1 }}>
                                <TableCell sx={{ fontWeight: 'bold' }}>S.No</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>GSTIN</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Brand</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>SKU</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Requested Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Approved Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Requestee Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Employee ID </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Assignee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(showFilteredData ? filteredData : rows).length > 0 ? (
                                (showFilteredData ? filteredData : rows).map((barcodeList, index) => (
                                    <TableRow key={barcodeList.barcode}>
                                        <TableCell sx={{ padding: '3vh' }} align="right">{index + 1}</TableCell>
                                        <TableCell>
                                            {barcodeList.barcode ? (
                                                <>
                                                    <span>{barcodeList.barcode}</span>&nbsp;&nbsp;
                                                    <img
                                                        // onClick={() => handleOpenSnackbar()} 
                                                        src={BarcodeIcon} alt="barcode" style={{ cursor: 'pointer' }} />
                                                </>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }} component="th" scope="row">
                                            {barcodeList.brandName}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.productName}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <Tooltip title={barcodeList.description} arrow placement="left-start">
                                                <span>{barcodeList.description}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.sku}</TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{formatDate(barcodeList.requestedDate)}</TableCell>
                                        <TableCell>
                                            {barcodeList.assignedDate ? (
                                                <span style={{ color: 'green', backgroundColor: '#1C98191A', borderRadius: '5px', padding: '4px' }}>{formatDate(barcodeList.assignedDate)}</span>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.employeeName}</TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.empNo}</TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.assignee}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={11} sx={{ textAlign: 'center', padding: '3vh' }}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Box>
            {/* <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={SlideTransition}>
                <SnackbarContent {...snackbarContentProps} />
            </Snackbar> */}
            {/* <Snackbar open={barcodeSnackbarOpen} autoHideDuration={3000} onClose={handleCloseBarcodeSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: 'white',
                        color: messageType === 'success' ? 'green' : 'red',
                        borderRadius: '8px',
                        padding: '1.5vh',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                    }
                }}
                TransitionComponent={SlideTransition}>
                <SnackbarContent message={(
                    <div style={{ display: 'flex', alignItems: 'center', background: 'white' }}>
                        <img src={messageType === 'success' ? SuccessIcon : CrossIcon} alt="status icon" />
                        <span style={{ marginInline: '1vh' }}>
                            {snackbarMessage}
                        </span>
                        <img src={CloseIcon} onClick={handleCloseBarcodeSnackbar} style={{ cursor: 'pointer' }} alt="close" />
                    </div>
                )}
                />
            </Snackbar> */}
            <Drawer

                anchor='right'
                open={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
            >
                {list('right')}
            </Drawer>
        </Grid >
    );
};

export default BarcodeEmployee;
