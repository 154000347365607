import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button, Stack, TextField, InputAdornment, Snackbar, SnackbarContent, Slide, SlideProps, Tooltip, Modal } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextFieldsInput from "../commons/text-fields-input";
import axios from "axios";
import SearchIcon from '../../icons/SearchIcon.svg';
import SuccessIcon from '../../icons/successIcon.svg';
import CloseIcon from '../../icons/closeIcon.svg';
import CrossIcon from '../../icons/crossIcon.png';
import "./BarcodeRequest.css";
import BarcodeIcon from '../../icons/barcodeIcon.svg';

const BarcodeRequest = () => {

    interface BarcodeItem {
        sno: number,
        employeeName: any,
        employeeId: any,
        brandName: string,
        productName: any,
        description: any,
        requestedDate: any,
        sku: any,
        empNo: any
    }

    const initialOnClickData = {
        sno: '',
        employeeName: '',
        empNo: '',
        brandName: '',
        productName: '',
        description: '',
        requestedDate: '',
        sku: '',
        id: '',
    };
    const [rows, setRows] = useState<BarcodeItem[]>([]);
    const [onCLickData, setOnclickData] = useState(initialOnClickData);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [barcode, setBarcode] = useState<string | null>(null);
    const [brandName, setBrandName] = useState<string | null>(null);
    const [productName, setProductName] = useState<string | null>(null);


    const formatDate = (dateString: any) => {
        if (!dateString) {
            return '-';
        }
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const router = useNavigate();

    useEffect(() => {
        getRequestList();
    }, []);

    const handleOnclickData = (data: any) => {
        setOnclickData(data);
    };

    const submitRequest = async (id: any) => {
        if (!id) {
            handleOpenSnackbar('Select any one of the rows', 'error');
            return;
        }

        const token = sessionStorage.getItem('AccessToken');
        try {
            const response = await axios.post(
                `https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/admin/assignBarcodeById/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                getRequestList();
                setOnclickData(initialOnClickData);
                setBrandName(response.data.brandName);
                setProductName(response.data.productName);
                setBarcode(response.data.barcode);
                setIsModalOpen(true);
            }
        } catch (error) {
            handleOpenSnackbar('Barcode Not Available', 'error');
        }
    };


    const handleOpenSnackbar = (message: string, type: 'success' | 'error') => {
        setSnackbarMessage(message);
        setSnackbarType(type);
        setSnackbarOpen(true);
    };

    const handleCancel = () => {
        setOnclickData(initialOnClickData);
    };

    const getRequestList = async () => {
        const token = sessionStorage.getItem('AccessToken');
        try {
            const requestList = await axios.get('https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/admin/getBarcodeRequest', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRows(requestList.data);
        } catch (err) {
            console.error('Error fetching employee barcode list:', err);
        }
    };

    const getSearchData = async (searchText: any) => {
        const token = sessionStorage.getItem('AccessToken');
        setRows([]);
        try {
            const res = await axios.get(`https://packaginglibrary-dev-backend.citpl.info/api/v1/barcodes/admin/searchByRequest?searchText=${searchText}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            setRows(res.data);
        }
        catch (error) {
            console.error('err', error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                getSearchData(searchTerm);
            } else {
                getRequestList();
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const snackbarContentProps = {
        message: (
            <div style={{ display: 'flex', alignItems: 'center', marginInlineStart: '3vh' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {snackbarType === 'success' ? <img src={SuccessIcon} alt="success" /> : <img src={CrossIcon} alt="error" />} &nbsp;
                    <span style={{ marginLeft: '8px' }}>{snackbarMessage}</span>
                </div>&nbsp;&nbsp;
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={CloseIcon} onClick={handleCloseSnackbar} style={{ cursor: 'pointer', marginInlineStart: '1vh' }} alt="close" />
                </div>
            </div>
        ),
        sx: {
            backgroundColor: 'white',
            color: snackbarType === 'success' ? 'green' : 'red',
            borderRadius: '8px',
            padding: '1.5vh'
        },
    };

    const SlideTransition = (props: SlideProps) => {
        return <Slide {...props} direction="left" />;
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };


    return (

        <Box sx={{ flexGrow: '1' }}>
            <Grid container sx={{ marginBlockStart: '2vh' }} columns={20}>
                <Grid item xs={14} sx={{ marginTop: '10vh' }}>  {/*Request */}
                    <Box>
                        <Box className="back-icon" sx={{ marginBlockEnd: '1vh', display: 'flex', alignItems: 'center' }} >
                            <img className="icon" onClick={() => router('/')} alt="back icon" style={{ marginLeft: '4vh', height: '23px', width: '24px', cursor: 'pointer' }} src="/assets/icon/back_arrow.svg" />
                            <Typography onClick={() => router('/')} sx={{ marginLeft: '0%', fontSize: '17px', cursor: 'pointer' }}>Back</Typography>
                        </Box>
                        <Grid container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginBlockEnd: '4vh' }}>
                            <Grid sx={{ marginLeft: '5vh' }} item xs={3}>
                                <Typography variant="h6">Barcode Request</Typography>
                            </Grid>
                            <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '2vh', marginInlineEnd: '7vh' }} item xs={8}>
                                <Grid item xs={5}>
                                    <TextField
                                        id="fsrch"
                                        name="fsrch"
                                        placeholder="Search"
                                        size="small"
                                        style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: 'none',
                                                },
                                            }
                                        }}
                                        onChange={handleSearchChange}
                                    />
                                </Grid>
                                <Button sx={{ borderRadius: '1vh', textTransform: 'none', marginRight: '-4.2vh' }} variant="outlined" onClick={() => router('/barcodeList')}> Barcode List</Button>
                            </Grid>
                        </Grid>
                        <TableContainer className="custom-scrollbar" component={Paper} style={{
                            marginInlineStart: '5vh', width: '95%', maxHeight: '500px', overflowY: 'scroll', overflowX: 'scroll'
                        }}>
                            <Table sx={{ minWidth: '800px', maxWidth: '100%' }} aria-label="simple table">
                                <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                                    <TableRow style={{ position: 'sticky', top: 0, backgroundColor: '#f0f0f0', zIndex: 1 }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>S.No</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Requestee Name</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Employee ID</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Brand</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Request Date</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>SKU</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.length > 0 ? (
                                        rows.map((requestList, index) => (
                                            <TableRow key={requestList.employeeId} onClick={() => handleOnclickData(requestList)}>
                                                <TableCell sx={{ padding: '3vh' }} align="right">{index + 1}</TableCell>
                                                <TableCell sx={{ padding: '3vh' }} component="th" scope="row">
                                                    {requestList.employeeName}
                                                </TableCell>
                                                <TableCell sx={{ padding: '3vh' }}>{requestList.empNo}</TableCell>
                                                <TableCell sx={{ padding: '3vh' }}>{requestList.brandName}</TableCell>
                                                <TableCell sx={{ padding: '3vh' }}>{requestList.productName}</TableCell>
                                                <TableCell sx={{ maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    <Tooltip title={requestList.description} arrow placement="left-start">
                                                        <span>{requestList.description}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell sx={{ padding: '1vh' }}>{formatDate(requestList.requestedDate)}</TableCell>
                                                <TableCell sx={{ padding: '2vh' }}>{requestList.sku}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={8} sx={{ textAlign: 'center', padding: '3vh' }}>
                                                No Data
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <div style={{ borderLeft: "3px solid #CAC4D0", height: "600px", position: 'absolute', marginTop: '80px', border: '1px solid rgba(202, 196, 208, 1)' }}>
                    </div>
                </Grid>
                <Grid item xs={5} style={{ marginLeft: "-3%" }}> {/*Assign */}
                    <Box sx={{ marginTop: '80px' }}>
                        <Typography variant="h6">Assign Barcode</Typography>
                    </Box>
                    <Box sx={{ marginBlock: '3vh' }}>
                        <Typography variant="body2">Requestee Name </Typography>
                        <div style={{ marginLeft: '-50%' }}>
                            <TextFieldsInput label="Enter Requestee Name" size="small" width="100%" value={onCLickData?.employeeName} disabled={true} name="requesteeName" type="text" placeholder="Enter Requestee Name" />
                        </div>
                    </Box>
                    <Box sx={{ marginBlockEnd: '2vh' }}>
                        <Typography variant="body2">Employee ID </Typography>
                        <div style={{ marginLeft: '-50%' }}>
                            <TextFieldsInput label="Enter Employee ID" size="small" width="100%" value={onCLickData?.empNo} disabled={true} name="employeeId" type="text" placeholder="Enter Employee ID" />
                        </div>
                    </Box>
                    <Box sx={{ marginBlockEnd: '2vh' }}>
                        <Typography variant="body2">Brand </Typography>
                        <div style={{ marginLeft: '-50%' }}>
                            <TextFieldsInput label="Enter Brand" size="small" width="100%" disabled={true} value={onCLickData?.brandName} name="brand" type="text" placeholder="Enter Brand" />
                        </div>
                    </Box>
                    <Box sx={{ marginBlockEnd: '2vh' }}>
                        <Typography variant="body2">Product </Typography>
                        <div style={{ marginLeft: '-50%' }}>
                            <TextFieldsInput label="Enter Product" size="small" width="100%" disabled={true} value={onCLickData?.productName} name="product" type="text" placeholder="Enter Product" />
                        </div>
                    </Box>
                    <Box sx={{ marginBlockEnd: '2vh' }}>
                        <Typography variant="body2">Description </Typography>
                        <div style={{ marginLeft: '-50%' }}>
                            <TextFieldsInput label="Enter Description" size="small" width="100%" disabled={true} value={onCLickData?.description} name="description" type="text" placeholder="Enter Description" />
                        </div>
                    </Box>
                    <Box sx={{ marginBlockEnd: '2vh' }}>
                        <Typography variant="body2">SKU </Typography>
                        <div style={{ marginLeft: '-50%' }}>
                            <TextFieldsInput label="Enter SKU" size="small" width="100%" disabled={true} value={onCLickData?.sku} name="sku" type="text" placeholder="Enter SKU" />
                        </div>
                    </Box>
                    <Stack spacing={2} direction="row" style={{ marginInlineStart: '50.5%', marginBlockStart: '3vh' }}>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" onClick={() => submitRequest(onCLickData.id)} sx={{ textTransform: 'none' }}>Submit</Button>
                    </Stack>
                </Grid>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={SlideTransition}>
                <SnackbarContent {...snackbarContentProps} />
            </Snackbar>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    p: 4,
                    border: 'none'
                }}>
                    <img style={{ marginInlineStart: '100%', marginBlockStart: '-10vh', cursor: 'pointer' }} onClick={handleModalClose} src={CloseIcon} />
                    <Typography id="success-modal-title" variant="h6" component="h1" sx={{ color: 'rgba(28, 152, 25, 1)', textAlign: 'center', marginBlockStart: '-3.8vh' }}>
                        Assigned Successful!
                    </Typography>
                    <p id="success-modal-description">
                        Barcode has been assigned successfully
                    </p>
                    <br />
                    <Typography sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', marginInlineStart: '17%' }}>
                        <Typography><b>Brand &nbsp;</b>    &nbsp;&nbsp;&nbsp;&nbsp; <span style={{ color: 'rgba(84, 84, 84, 1)' }}>{brandName}</span></Typography>
                        <br />
                        <Typography><b>Product </b>  &nbsp;&nbsp; <span style={{ color: 'rgba(84, 84, 84, 1)' }}>{productName}</span></Typography>
                        <br />
                        <Typography><b>Barcode </b>  &nbsp;&nbsp;
                            <span style={{ color: 'rgba(84, 84, 84, 1)' }}>{barcode}</span>&nbsp;
                            <img src={BarcodeIcon} alt="barcode" style={{ cursor: 'pointer' }} />
                        </Typography>
                    </Typography>
                </Box>
            </Modal>

        </Box>

    );
};

export default BarcodeRequest;

